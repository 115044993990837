const initState = {
  displayName: "Wing Watcher Samiti",
  displayNameNP: "",
  phoneNumber: "",
  addressLine1: "",
  district: "Kathmandu",
  city: "Kathmandu",
  email: "",
};

const company = (state = initState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default {
  company,
};
