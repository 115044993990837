import store from "admin/redux";
import React from "react";
import {Provider} from "react-redux";
import AdminContainer from "./AdminContainer";
import "../admin.scss";

const Admin = () => {
  return (
    <Provider store={store}>
      <AdminContainer />
    </Provider>
  );
};

export default Admin;
