import {server} from "admin/server";
import A from ".";

export const getAllMeta = (url) => {
  return server
    .get(url ? `/all/${url}` : "/all")
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
export const setAllMeta = (payload) => ({
  type: A.SET_ALL_FIELDS,
  payload: payload,
});
export const setLoading = (payload) => ({
  type: A.SET_LOADING,
  payload: payload,
});
