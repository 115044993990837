const adminRoutes = {
  home: "/admin",
  login: "/admin/login",
  aboutUs: "/admin/about-us",
  // CHARTS
  createReports: "/admin/create-reports",
  // EXCEL DUMP
  fileDump: "/admin/dump",
  //  USERS
  users: "/admin/users",
  usersAdd: "/admin/users/add",
  usersEdit: "/admin/users/edit",
  usersView: "/admin/users/:id",
  //  ROLES
  roles: "/admin/roles",
  rolesAdd: "/admin/roles/add",
  rolesView: "/admin/roles/:id",
  rolesEdit: "/admin/roles/edit",
  // BIRDS
  birds: "/admin/birds",
  birdAdd: "/admin/birds/add",
  birdEdit: "/admin/birds/edit",
  birdView: "/admin/birds/view",
  // CHECKLISTS
  checklists: "/admin/checklists",
  checklistEdit: "/admin/checklists/edit",
  checklistView: "/admin/checklists/view",
  checklistMap: "/admin/checklists/mapping",
};

export default adminRoutes;
