import React, {useEffect} from "react";
import "../scss/theme.scss";
import AdminLogin from "./AdminLogin";
import LayoutComponent from "./Layout";
import {connect} from "react-redux";
import {authorize} from "admin/redux/actions/authAction";
import {getAllMeta} from "admin/redux/actions/miscAction";

const AdminContainer = (props) => {
  useEffect(() => {
    props.dispatch(authorize());
  }, []); //eslint-disable-line

  if (!props.authUser.isAuthenticated) {
    if (props.authUser.isLoading)
      return (
        <div className="d-flex w-100 vh-100 justify-content-center align-items-center">
          <div className="spinner-border text-primary"></div>
        </div>
      );
    else return <AdminLogin />;
  }

  if (props.permissions.isLoading)
    return (
      <div className="d-flex w-100 vh-100 justify-content-center align-items-center">
        <div className="spinner-border text-primary"></div>
      </div>
    );

  return <LayoutComponent />;
};

export default connect((state) => ({
  authUser: state.authUser,
  permissions: state.permissions,
}))(AdminContainer);
