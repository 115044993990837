import A from "../actions";

const initialState = {
  sidebarOpened: false,
  sidebarStatic: false,
};

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case A.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpened: !state.sidebarOpened,
      };
    case A.OPEN_SIDEBAR:
      return {
        ...state,
        sidebarOpened: true,
      };
    case A.CLOSE_SIDEBAR:
      return {
        ...state,
        sidebarOpened: false,
      };
    default:
      return state;
  }
};

const runtime = (state = {}, action) => {
  switch (action.type) {
    case A.SET_RUNTIME_VARIABLE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};

export default {
  navigation,
  runtime,
};
