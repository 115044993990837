enum A {
  // MISC
  SET_ALL_FIELDS,
  // AUTH
  SET_LOGIN,
  GET_LOGIN,
  SET_PERMISSIONS,
  SET_LOADING,
  // EXCEL DATA
  SET_EXCEL_DATA,
  // KOBO TOOLBOX
  SET_KOBO_TOOLBOX_API_ID,
  TOGGLE_SIDEBAR,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  SET_RUNTIME_VARIABLE,
}

export default A;
