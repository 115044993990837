import ThemeOptions from "./ThemeOptions";
import miscReducer from "./miscReducer";
import authReducer from "./authReducer";
import companyReducer from "./companyReducer";
import navigationReducer from "./navigationReducer";

const reducers = {
  ...ThemeOptions,
  ...miscReducer,
  ...authReducer,
  ...companyReducer,
  ...navigationReducer,
};

export default reducers;
